/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-dark-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

body {
  margin: 0;
}

.form-error {
  color: var(--red-500);
}

:host {
  ::ng-deep .ui-button:focus,
  ::ng-deep .ui-button:enabled:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}
.p-button:focus {
  outline: none;
  box-shadow: none;
}
